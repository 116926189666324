<template>
  <div class="rechargeDetail">
    <el-row :gutter="20" class="center">
      <el-col :span="12" :offset="6">
        <div class="formBox">
          <i
            v-if="status == 200"
            :class="'el-icon-success ' + (status == 200)"
          >
          </i>
          <i
            v-if="status != 200"
            :class="'el-icon-error ' + (status == 200)"
          >
          </i>
          <p class="descriptionStatus">{{ descriptionStatus }}</p>
          <p class="descriptionCause" v-if="descriptionCause">
            {{ descriptionCause }}
          </p>
          <div v-else>
            <p class="descriptionCause">{{ $t("获得") }} {{ description2 }} {{ $t("tokens") }}</p>
            <p class="descriptionCause">{{ $t("有效期") }} {{ description3 }} {{ $t("多天") }}</p>
            <p class="descriptionCause">{{ $t("订单号") }} {{ description1 }}</p>
          </div>
          <el-button
            :type="status == 200 ? 'danger' : 'primary'"
            size="small"
            @click="back()"
          >
            {{ status == 200 ? $t("返回") : $t("返回兑换页") }}
          </el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  name: "rechargeDetail",
  data() {
    return {
      detail: '',
      status: '',
      no: '',
      usage: '',
      validity: '',
      descriptionStatus: "",
      descriptionCause: "",
      description1: "",
      description2: "",
      description3: ""
    };
  },
  mounted() {
    this.detail = this.$route.query.detail
    this.status = this.$route.query.status
    this.no = this.$route.query.no
    this.usage = this.$route.query.usage
    this.validity = this.$route.query.validity
    if (this.status == 200) {
      let no = this.$route.query.no;
      let usage = this.$route.query.usage;
      let validity = this.$route.query.validity;
      this.descriptionStatus = this.$t("兑换成功");
      this.descriptionCause = "";
      this.description1 = no;
      this.description2 = usage;
      this.description3 = validity;
    } else {
      this.descriptionStatus = this.$t("兑换失败");
      this.descriptionCause = this.$t("请再次尝试");
      this.description1 = this.description2 = this.description3 = "";
    }
  },
  methods: {
    back() {
      if (this.status == 200) this.$router.push({path:"/payForm"});
      else this.$router.push("recharge");
    }
  }
};
</script>
<style lang='less' scoped>
.rechargeDetail{
  position: relative;
  height: 100vh;
  .center{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
  .formBox{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    i {
      font-size: 10rem;
      margin-bottom: 1rem;
    }
    .false{
        color: #f75b5d;
    }
    .true{
        color: #21d161;
    }
    .descriptionStatus{
      font-size: 1.7rem;
      font-weight: 600;
      margin: 5px 0;
    }
    .descriptionCause{
      font-size: 1.4rem;
      font-weight: 500;
      margin: 5px 0 0 0;
      text-align: center;
    }
    .el-button{
        margin-top: 1.5rem;
    }
  }
}
.el-row {
  &:last-child {
    width: 100%;
    margin-bottom: 0;
  }
}
</style>
